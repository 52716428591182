
// import { create } from "../../api/model.js";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from "element-plus";
import { AdminStatus } from '@/shared/types/AdminData';
export default defineComponent({
  name: "model-add",
  components: {},
  data: () => {
    return {
      params: {
        //接口入参
        model_name: "", //
        table_name: "",
        status: "1",
      },

      paramsRules: {
        //校验规则
        model_name: [
          { required: true, message: "模型名称", trigger: "blur" },
          {
            min: 2,
            max: 80,
            message: "名称长度在 2 到 80 个字符之间",
            trigger: "blur",
          },
        ],
        table_name: [
          { required: true, message: "新增表名", trigger: "blur" },
          {
            min: 2,
            max: 80,
            message: "名称长度在 2 到 80 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleAttr(e: any) {
      console.log("e-->", e);
    },
    change(v: string) {
      console.log(v);
      this.params.table_name = v;
    },
    //新增
    async create() {
      try {
        console.error(this.params);
        let res = await client.callApi("model/Add",{
        model_name:this.params.model_name,
        table_name:this.params.table_name,
        status:parseInt(this.params.status) as AdminStatus
      });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        } else {
          ElMessage({
            message: res.err.message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    submit(formName: string) {
      let ref:any = this.$refs[formName];
      ref.validate((valid: any) => {
        if (valid) {
          this.create();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
